import { useEffect } from 'react';

const useBackgroundColor = (backgroundColor: string | undefined): void => {
    useEffect(() => {
        if (!backgroundColor) {
            return;
        }
        // Store the original background
        const originalBackground = document.body.style.background;

        // Change the background using the passed color
        document.body.style.background = backgroundColor;

        // Cleanup function to reset the background when the component unmounts
        return () => {
            document.body.style.background = originalBackground;
        };
    }, [backgroundColor]);
}

export default useBackgroundColor;
