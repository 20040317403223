import {RMSServicesBlockModel} from './RMSServicesBlockModel';
import styles from './RMSServicesBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import transformToReactStyle, {
    transformToReactMaxWidthContainerStyle
} from '../../components/elements/rms-css-style-block/transformToReactStyle';
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses';
import {motion} from "framer-motion"
import {configOpacityScale} from '../../animations/framer-motion/motion-configs';
import RMSServiceSmall
    from '../../../../features/rms-projects/features/services/components/rms-service-small/RMSServiceSmall';

export default function RMServicesBlock({blockElement}: {blockElement: RMSServicesBlockModel}) {
    const data = blockElement.value

    return (
        <div
            className={multipleClassName(styles.RMSServicesBlock, "IgnoreMaxWidth", "MaxWidthOuterContainer")}
            style={transformToReactStyle(blockElement.value.css_style)}
        >
            <div className={multipleClassName(
                styles.inner,
                "MaxWidthContainer",
                transformToReactContainerClasses(blockElement.value.css_style))}
                 style={transformToReactMaxWidthContainerStyle(blockElement.value.css_style)}>
                <motion.div {...configOpacityScale}
                    className={styles.header}>
                    {data.title && <h2>{ data.title }</h2>}
                    {data.subtitle && <h3>{ data.subtitle }</h3>}
                </motion.div>
                <div className={styles.content}>
                    {data.services && data.services.map((service, index) => {
                        return <RMSServiceSmall key={index} service={service} />
                    })}
                </div>
            </div>
        </div>
    );
}