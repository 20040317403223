import styles from './RMSTechnologiesRatedContainer.module.scss';
import {RMSTechnology} from '../../models/RMSTechnology';
import RatingHorizontalContainer
    from '../../../general/components/containers/rating-horizontal-container/RatingHorizontalContainer';
import {RMSEmployeeTechnology} from '../../../employee/models/RMSEmployee';
import {configOpacityScale} from '../../../../../../main/elements/animations/framer-motion/motion-configs';
import { motion } from 'framer-motion';

export default function RMSTechnologiesRatedContainer({technologies, className, title}: {
    technologies: RMSEmployeeTechnology[],
    title?: string,
    className?: string
}) {
    return (
        <motion.div {...configOpacityScale} className={styles.RMSTechnologiesRatedContainer}>
            {title && <h4 className={styles.Title}>{title}</h4>}
            {
                technologies?.filter(tech => tech.rating)
                    .map((tech, index) => (<>
                        <RatingHorizontalContainer
                            rating_percentage={(tech.rating!/10)*100}
                            showPercentage={true}
                            text={tech.technology.name} key={index}
                            className={styles.RatingContainer}/>
                    </>))
            }
        </motion.div>
    );
}