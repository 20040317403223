import styles from './RMSTechnologyShort.module.scss';
import {RMSTechnology} from '../../models/RMSTechnology';
import {motion} from "framer-motion"
import {
    configOpacityScale,
    configOpacitySlideLeft
} from '../../../../../../main/elements/animations/framer-motion/motion-configs';
import multipleClassName from '../../../../../../utils/multipleClassName';
import {Link} from 'react-router-dom';

export default function RMSTechnologyShort({technology}: {technology: RMSTechnology}) {
    const style: React.CSSProperties = {};
    style.background = technology.background_color ?? "var(--rms-color-light-tint)";
    style.color = technology.font_color ?? "var(--rms-color-dark)";

    return (
        <Link to={"/technologies/"+technology.slug} key={technology.id}>
        <motion.div
        {...configOpacityScale}
        transition={{duration: 1.5, type: "spring", stiffness: 400, damping: 17}}
        whileHover={{scale: 1.1}}
        whileTap={{scale: 0.9}}
        className={multipleClassName(styles.TechnologyContainer)}
        style={style}
        >
            {technology.name}
        </motion.div>
        </Link>
    );
}