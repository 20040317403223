import {RMSHTMLModel} from './RMSHTMLModel';
import styles from './RMSHTMLBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import transformToReactStyle from '../../components/elements/rms-css-style-block/transformToReactStyle';
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses';
import {useEffect, useRef} from 'react';

export default function RMSHTMLBlock({blockElement}: {blockElement: RMSHTMLModel}) {
    return (
        <div className={
            multipleClassName(styles.RMSHTMLBlock, transformToReactContainerClasses(blockElement.value.css_style))
        }
             style={transformToReactStyle(blockElement.value.css_style)}
             dangerouslySetInnerHTML={{ __html: blockElement.value.html ?? "" }} />
    );
}