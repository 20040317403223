import {RMSChoosedImageRendition} from '../RMSChoosedImageElementModel';

const getNearestRendition = (
    renditions: RMSChoosedImageRendition[],
    targetValue: number,
    valueOfRendition: (rendition: RMSChoosedImageRendition) => number | undefined
) => {
    if (!renditions || renditions.length == 0) {
        return null;
    }

    return renditions.reduce((previousValue, currentValue) => {
        const currentRendValue = valueOfRendition(currentValue);
        const prevRendValue = valueOfRendition(previousValue);

        if (!currentRendValue) {
            return previousValue;
        } else if (!prevRendValue) {
            return currentValue;
        }

        const previousDiff = prevRendValue - targetValue
        const currentDiff = currentRendValue - targetValue

        if (currentDiff < 0) { // use the biggest version, if the previous one is below the current windowSize
            return (currentDiff > previousDiff) ? currentValue : previousValue;
        } else { // otherwise use the closest version to 0 (best value)
            if (previousDiff < 0) {
                return currentValue;
            }
            return (currentDiff < previousDiff) ? currentValue : previousValue;
        }
    })
};

export default getNearestRendition;