import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import RMSCMSMainMenuElement from '../models/RMSCMSMainMenu';
import RMSApiConfig from '../../../api/models/RMSApiConfig';
import RMSApi from '../../../api/RMSApi';

export const fetchMainMenu = createAsyncThunk(
    'api/cms/menu/fetchMainMenu',
    async (payload: {
                apiConfig: RMSApiConfig,
           }) => {
        try {
            //process.env.REACT_APP_API_BASE_URL
            const response = await RMSApi.get(`/api/v2/main-menu/`, {
                ...payload.apiConfig,
            })
            const data: RMSCMSMainMenuElement[] = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);