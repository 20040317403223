import {DHDownloadBlockModel} from './DHDownloadBlockModel';
import styles from './DHDownloadBlock.module.scss';
import {DownloadElement} from '../../components/elements/download-element/DownloadElement';

export default function DHDownloadBlock({blockElement}: {blockElement: DHDownloadBlockModel}) {
    return (
        <div className={styles.DHDownloadBlock}>
            <DownloadElement />
        </div>
    );
}