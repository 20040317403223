// useReactMatomoHook.ts
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useMatomoReactLinkTracking = () => {
    const location = useLocation();
    useEffect(() => {
        // window._paw should be inizialized
        if (!window._paq) {
            window._paq = [];
        }

        // set the new url
        window._paq.push(['setCustomUrl', window.location.href]);
        // set the action_name with the current document title
        window._paq.push(['setDocumentTitle', document.title]);
        // track the page call, to push the changes to matomo
        window._paq.push(['trackPageView']);
    }, [location]);
};

export default useMatomoReactLinkTracking;