import './TopHeader.scss'
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useRMSApiConfig} from '../../../../features/api/contexts/RMSApiContext';
import {useEffect, useState} from 'react';
import {fetchMainMenu} from '../../../../features/cms/menus/actions/fetchMainMenu';
import {useMainMenuWithPage} from '../../../../features/cms/hooks/useMainMenuSelector';
import multipleClassName from '../../../../utils/multipleClassName';
import rmsLogo from '../../../../../assets/images/LogoHorizontal100h.gif'
import mountainBottomOrange from '../../../../../assets/images/mountainBottomOrange.png';

function MenuItem({name = "Menu Item", menuLink= "/"}) {
    return (
        <Link to={menuLink} className="MenuItem rms-clickable rms-hover-scale">{name}</Link>
    )
}

export default function TopHeader(){
    const dispatch = useDispatch();
    const apiConfig = useRMSApiConfig();
    const {
        mainMenuItems,
        loadingState: pageLoadingState,
        error: pageError} = useMainMenuWithPage();

    const [showMenu, setShowMenu] = useState(false);

    useEffect(() => {
        dispatch(fetchMainMenu({apiConfig: apiConfig}) as any);
    }, [dispatch]);

    const renderMenu = (
        mainMenuItems && mainMenuItems.map((item, index) => {
            return <MenuItem key={index} name={item.menu_text} menuLink={item.path}/>
        })
    )

    return (
        <div className="TopHeader" data-testid="top-header">
            <img src={mountainBottomOrange} className="mountainBottom"></img>
            <div className="TopHeader__Line">
                <div className="TopHeader__Line__Logo rms-clickable rms-hover-scale">
                    <Link to="/"><img alt='RM-Softwares' src={rmsLogo}/></Link>
                </div>
                <div className="TopHeader__MobileButton">
                    <i className="bi bi-list" onClick={() => setShowMenu(!showMenu)}></i>
                </div>
                <div className={multipleClassName("TopHeader__Line__Menu")}>
                    { renderMenu }
                </div>
                <div className={multipleClassName("MobileMenu", showMenu ? "show" : "hide")} onClick={() => setShowMenu(!showMenu)}>
                    <div className="MobileMenuContainer">
                        <i className="bi bi-x-lg closeIcon"></i>
                        <div className="MobileMenuHeader">
                            <h2>Menü</h2>
                            <div className="Seperator"></div>
                        </div>
                        <div className="MenuItemContainer">
                            { renderMenu }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}