import styles from './RMSDialogCard.module.scss';
import {ReactNode} from 'react';
import multipleClassName from '../../../../utils/multipleClassName';

type RMSDialogProps = {
    children: ReactNode;
    title?: string;
    footer?: ReactNode;
    titleNode?: ReactNode;
    startClose?: boolean;
};

export default function RMSDialogCard({ children, title, titleNode, footer, startClose }: RMSDialogProps) {

    // TODO: add logic to close the dialog (do it with a timer, to also support an animation)
    // TODO: don't implment the logic here, because it also affects the RMSDialog, and custom dialogs

    return (<div className={multipleClassName(styles.RMSDialogCard, (startClose) ? styles.close : "")}>
            {
                (title || titleNode) &&
                <div className={styles.header}>
                    { title && <h2 className={styles.title}>{title}</h2>}
                    { titleNode }
                </div>
            }
            <div className={styles.content}>
                { children }
            </div>
            {
                footer &&
                <div className={styles.footer}>
                    { footer }
                </div>
            }
        </div>
    );
}