import {ProjectPageModel} from './ProjectPageModel';
import styles from './ProjectPage.module.scss';
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock';
import useBackgroundColor from '../../../hooks/style/useBackgroundColor';
import DefaultPageContainer from '../../components/containers/default-page-container/DefaultPageContainer';
import RMSTechnologiesContainer
    from '../../../../features/rms-projects/features/technologies/components/rms-technologies-container/RMSTechnologiesContainer';
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl';
import multipleClassName from '../../../../utils/multipleClassName';
import {Link} from 'react-router-dom';
import LeftRightFloatingContainer
    from '../../components/containers/left-right-floating-container/LeftRightFloatingContainer';

export default function ProjectPage({page}: {page: ProjectPageModel}) {
    useBackgroundColor('var(--rms-color-primary)');

    if (page.project.custom_design) {
        return <DefaultPageContainer className={styles.ProjectPage}>
            <DHContentBlock blocks={page.project.content}/>
        </DefaultPageContainer>
    }

    return (
        <DefaultPageContainer title={page.project.name} className={styles.ProjectPage}>
            <RMSTechnologiesContainer technologies={page.project.technologies} className={styles.TechnologiesContainer}/>
            <div className={styles.ProjectContainer}>
                <LeftRightFloatingContainer left={false} floatingContainerClassName={styles.FloatingContainer}
                                            floatingChildren={
                     page.project.title_picture && <>
                                <img src={rmsCmsImageUrl(page.project.title_picture)}
                                     alt={page.project.name}
                                     className={styles.ProjectImage}/>
                                { page.project.showcase_url && <div className={styles.ShowCaseButtonContainer}>
                                    <Link to={page.project.showcase_url}>
                                        <button className={multipleClassName( "primary", "rms-hover-scale")}>
                                            <i className="bi bi-globe2"/> Showcase
                                        </button>
                                    </Link>
                                </div>}</>
                }>
                    { page.project.description &&
                        <div dangerouslySetInnerHTML={{__html: page.project.description}}></div>
                    }
                </LeftRightFloatingContainer>
            </div>
            <DHContentBlock blocks={page.project.content}/>
        </DefaultPageContainer>);
}