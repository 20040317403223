import {HomeHeaderBlockModel} from './HomeHeaderBlockModel';
import styles from './HomeHeaderBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import {useEffect, useState} from 'react';
import mountainbg1 from '../../../../../assets/images/mountainbg1.png'
import mountainbg2 from '../../../../../assets/images/mountainbg2.png'
import mountainbg3 from '../../../../../assets/images/mountainbg3.png'
import mountainbg4 from '../../../../../assets/images/mountainbg4.png'
import mountainbg5 from '../../../../../assets/images/mountainbg5.png'
import mountainbg6 from '../../../../../assets/images/mountainbg6.png'
import mountainBottom from '../../../../../assets/images/mountainbottom.png'
import {ParallaxBanner} from 'react-scroll-parallax';
import {motion} from "framer-motion"
import {
    configOpacity,
    configOpacityScale,
    configOpacitySlideLeft,
    configOpacitySlideRight, configOpacitySlideUp
} from '../../animations/framer-motion/motion-configs';
import trackEvent from '../../../../features/rms-matomo/track-event';

export default function HomeHeaderBlock({blockElement}: {blockElement: HomeHeaderBlockModel}) {
    const blockValue = blockElement.value;
    const [scrollY, setScrollY] = useState(0);

    function scrollToNextH1() {
        trackEvent(
            {
                category: 'Button',
                action: 'Click',
                name: 'Scroll To Next H1 Element'
            }
        )
        // Find all h1 elements with the 'glowing-text' class
        const h1Elements = document.querySelectorAll('h1.glowing-text');
        const currentScrollPosition = window.pageYOffset;

        // Find the next h1 element based on the current scroll position
        const nextH1 = Array.from(h1Elements).find((h1) => {
            // Type assertion to HTMLElement to access offsetTop
            const element = h1 as HTMLElement;
            return element.offsetTop > currentScrollPosition;
        });

        // If a next h1 is found, calculate the position and scroll to it with a 200px offset above
        if (nextH1) {
            const scrollToPosition = (nextH1 as HTMLElement).offsetTop - 200; // Calculate the target position with 200px offset
            window.scrollTo({
                top: scrollToPosition,
                behavior: 'smooth'
            });
        }
    }

    // Update the scrollY value whenever the user scrolls
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const opacity = 1 - scrollY / 200;

    return (
        <div
            className={multipleClassName(styles.HomeHeaderBlock, "IgnoreMaxWidth")}
            style={{
                background: (blockValue.background_image?.image_versions.length) ? "transparent" : "var(--rms-color-primary)"
            }}
        >
            { <div className={styles.HomeHeaderBackground}>
                <ParallaxBanner
                    className="banner"
                    layers={[
                        {
                            image: mountainbg1,
                            translateY: [0, 50],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.1s"
                            }
                        },
                        {
                            image: mountainbg2,
                            translateY: [0, 45],

                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.3s"
                            }
                        },
                        {
                            image: mountainbg3,
                            translateY: [0, 30],

                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.6s"
                            }
                        },
                        {
                            image: mountainbg4,
                            translateY: [0, 25],

                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "bounceInUp 1.5s"
                            }
                        },
                        {
                            image: mountainbg5,
                            translateY: [0, 20],

                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.9s",
                                marginTop: -10
                            }
                        },
                        {
                            image: mountainbg6,
                            translateY: [0, 5],

                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 1.2s"
                            }
                        }
                    ]}
                />
            </div> }
            <div className={styles.inner}>
                <motion.div className={styles.innerRightSide}>
                    <motion.h1 {...configOpacitySlideLeft} className="glowing-text">{blockValue.title}</motion.h1>
                    <motion.h2 {...configOpacitySlideRight}>{blockValue.subtitle}</motion.h2>
                    { blockValue.header_content && <motion.div {...configOpacitySlideUp} className={styles.headerContent} dangerouslySetInnerHTML={{ __html: blockValue.header_content }} /> }
                </motion.div>
            </div>
            <img src={mountainBottom} className={styles.mountainBottom}></img>

            { blockValue.show_more_text &&
            <div style={{
                opacity: opacity >= 0 ? opacity : 0,
                display: opacity >= 0 ? 'flex' : 'none'
            }}
                className={styles.showMoreTextContainer}>
                <div onClick={scrollToNextH1} className={multipleClassName(styles.box, "rms-hover-scale", "rms-clickable")}>
                    {blockValue.show_more_text}
                    <i className="bi bi-caret-down"></i>
                </div>
            </div>
            }
        </div>
    );
}