import {TechnologyPageModel} from './TechnologyPageModel';
import DHContentBlock from '../../blocks/dh-content-block/DHContentBlock';
import useBackgroundColor from '../../../hooks/style/useBackgroundColor';
import DefaultPageContainer from '../../components/containers/default-page-container/DefaultPageContainer';
import styles from '../TechnologyPage/TechnologyPage.module.scss';
import LeftRightFloatingContainer
    from '../../components/containers/left-right-floating-container/LeftRightFloatingContainer';
import rmsCmsImageUrl from '../../../../features/cms/utils/rmsCmsImageUrl';
import {Link} from 'react-router-dom';
import multipleClassName from '../../../../utils/multipleClassName';

export default function TechnologyPage({page}: {page: TechnologyPageModel}) {
    useBackgroundColor('var(--rms-color-primary)');

    if (page.technology.custom_design) {
        return <DefaultPageContainer className={styles.TechnologyPage}>
            <DHContentBlock blocks={page.technology.content}/>
        </DefaultPageContainer>
    }

    let floatingImg = (
        page.technology.title_picture ?
            <img src={rmsCmsImageUrl(page.technology.title_picture)}
                 alt={page.technology.name}
                 className={styles.TechnologyImage}/>
            : <div className={styles.TechnologyGraphicContainer}
            style={{
                backgroundColor: page.technology.background_color ?? "var(--rms-color-light-tint)",
                color: page.technology.font_color ?? "var(--rms-color-dark)"
            }}>
                <h3>{page.technology.name}</h3>
            </div>
    )

    return (
        <DefaultPageContainer title={page.technology.name} className={styles.TechnologyPage}>
            <div className={styles.TechnologyContainer}>
                <LeftRightFloatingContainer left={false} floatingContainerClassName={styles.FloatingContainer}
                                            floatingChildren={<>
                                                {floatingImg}
                                                    { page.technology.showcase_url && <div className={styles.ShowCaseButtonContainer}>
                                                        <Link to={page.technology.showcase_url}>
                                                            <button className={multipleClassName( "primary", "rms-hover-scale")}>
                                                                <i className="bi bi-globe2"/> Website
                                                            </button>
                                                        </Link>
                                                    </div>}</>
                                            }>
                    { page.technology.description &&
                        <div dangerouslySetInnerHTML={{__html: page.technology.description}}></div>
                    }
                </LeftRightFloatingContainer>
            </div>
            <DHContentBlock blocks={page.technology.content}/>
        </DefaultPageContainer>);
}