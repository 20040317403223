import styles from './DefaultPageContainer.module.scss';
import WaveContainer from '../wave-container/WaveContainer';
import multipleClassName from '../../../../../utils/multipleClassName';
import SecondaryParallaxBanner from '../../banners/secondary-parallax-banner/SecondaryParallaxBanner';
import { motion } from 'framer-motion';
import {configOpacitySlideUp} from '../../../animations/framer-motion/motion-configs';

export default function DefaultPageContainer({children, title, className, backgroundClassName}: {
    children: React.ReactNode,
    title?: string,
    className?: string,
    backgroundClassName?: string
}) {
    return (<div className={multipleClassName(styles.DefaultPageContainer, className ?? '')}>
        <div className={multipleClassName(styles.WhiteBackground, backgroundClassName ?? '')}/>
        <SecondaryParallaxBanner/>
        <motion.div
            {...configOpacitySlideUp}
            animate={configOpacitySlideUp.whileInView}
        >
            <WaveContainer>
                {title && <div className={styles.Title}>
                    <h1>{title}</h1>
                </div>}
                {children}
            </WaveContainer>
        </motion.div>
    </div>);
}