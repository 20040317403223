interface TrackEventOptions {
    category: string;
    action: string;
    name?: string;
    value?: number;
}

const trackEvent = ({ category, action, name, value }: TrackEventOptions) => {
    window._paq = window._paq || [];
    const eventArray: (string | number)[] = ['trackEvent', category, action];
    if (name) eventArray.push(name);
    if (value) eventArray.push(value);
    window._paq.push(eventArray);
};

export default trackEvent;
