import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store from './app/main/store';
import 'bootstrap-icons/font/bootstrap-icons.css'
import RMSApiContext, {RMSApiContextProps} from './app/features/api/contexts/RMSApiContext';
import {defaultRMSApiConfig} from './app/features/api/models/RMSApiConfig';
import {createTheme, ThemeOptions, ThemeProvider} from '@mui/material';


const rmsApiContextProps: RMSApiContextProps = {
    config: {
        ...defaultRMSApiConfig,
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'Content-Type': 'application/json',
        }
    }
}

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#f7ab2c',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#b34945',
        },
        text: {
            primary: '#232325',
        },
    },
    typography: {
        h1: {
            fontSize: '4.8rem',
        },
    },
};

const theme = createTheme(themeOptions);

ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
).render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <RMSApiContext.Provider value={rmsApiContextProps}>
                    <App />
                </RMSApiContext.Provider>
            </Provider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
