import styles from './CVBlock.module.scss';
import {RMSCVSection} from '../../../models/RMSEmployee';
import CVSection from '../cv-section/CVSection';
import multipleClassName from '../../../../../../../utils/multipleClassName';

export default function CVBlock({cv, title, className}: {
    cv?: RMSCVSection[],
    title?: string,
    className?: string}) {
    if (!cv) {
        return null;
    }

    return <div className={multipleClassName(styles.CVBlock, className)}>
        { title && <h4 className={styles.Title}>{title}</h4>}
        {cv.map((cvSection, index) => {
            return <CVSection cvSection={cvSection} key={index} showLine={index !== cv.length - 1} />
        })}
    </div>
}