import {RMSEmployeesBlockModel} from './RMSEmployeesBlockModel';
import styles from './RMSEmployeesBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import transformToReactStyle, {
    transformToReactMaxWidthContainerStyle
} from '../../components/elements/rms-css-style-block/transformToReactStyle';
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses';
import {motion} from "framer-motion"
import {configOpacityScale} from '../../animations/framer-motion/motion-configs';
import RMSEmployeeSmall
    from '../../../../features/rms-projects/features/employee/components/rms-employee-small/RMSEmployeeSmall';

export default function RMSEmployeesBlock({blockElement}: {blockElement: RMSEmployeesBlockModel}) {
    const data = blockElement.value

    return (
        <div
            className={multipleClassName(styles.RMSEmployeesBlock, "IgnoreMaxWidth", "MaxWidthOuterContainer")}
            style={transformToReactStyle(blockElement.value.css_style)}
        >
            <div className={multipleClassName(
                styles.inner,
                "MaxWidthContainer",
                transformToReactContainerClasses(blockElement.value.css_style))}
                 style={transformToReactMaxWidthContainerStyle(blockElement.value.css_style)}>
                <motion.div {...configOpacityScale}
                    className={styles.header}>
                    {data.title && <h2>{ data.title }</h2>}
                    {data.subtitle && <h3>{ data.subtitle }</h3>}
                </motion.div>
                <div className={styles.content}>
                    {data.employees && data.employees.map((employee, index) => {
                        return <RMSEmployeeSmall key={index} employee={employee} />
                    })}
                </div>
            </div>
        </div>
    );
}