import {RMSProject} from '../../models/RMSProject';
import styles from './RMSProjectSmall.module.scss';
import {motion} from "framer-motion"
import rmsCmsImageUrl from '../../../../../cms/utils/rmsCmsImageUrl';
import {configOpacityScale} from '../../../../../../main/elements/animations/framer-motion/motion-configs';
import RMSTechnologiesContainer
    from '../../../technologies/components/rms-technologies-container/RMSTechnologiesContainer';
import { Link } from 'react-router-dom';

export default function RMSProjectsSmall({project}: {project: RMSProject}) {

    return (<motion.div {...configOpacityScale} className={styles.RMSProjectContainer}>
        <div className={styles.ImgContainer}>
            <Link to={"/projects/"+project.slug}>
                {project.title_picture && <img src={rmsCmsImageUrl(project.title_picture)}></img>}
            </Link>
        </div>
        <div className={styles.Content}>
            <Link to={"/projects/"+project.slug}>
                <div className={styles.Name}>{project.name}</div>
            </Link>
            <div className={styles.ShortDescription}>{project.name}</div>
            <RMSTechnologiesContainer technologies={project.technologies}/>
        </div>
    </motion.div>);
}