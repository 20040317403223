import {createAsyncThunk} from '@reduxjs/toolkit';
import RMSApiConfig from '../../api/models/RMSApiConfig';
import RMSApi from '../../api/RMSApi';
import ContactRequest from '../models/ContactRequest';

export const createContactRequest = createAsyncThunk(
    'api/v2/contact-requests',
    async (payload: {
                contactRequest: ContactRequest,
                apiConfig: RMSApiConfig,
           }) => {
        try {
            const response = await RMSApi.post(`/api/v2/contact-requests/`, payload.contactRequest,{
                ...payload.apiConfig,
            })
            const data: any = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);