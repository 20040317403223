import {RMSPagePreview} from '../../../pages/RMSPage/RMSPageModel';
import styles from './RMSPagePreviewElement.module.scss';
import rmsCmsImageUrl from '../../../../../features/cms/utils/rmsCmsImageUrl';
import multipleClassName from '../../../../../utils/multipleClassName';
import {Link} from 'react-router-dom';

export default function RMSPagePreviewElement({
    pagePreview
}: { pagePreview: RMSPagePreview }) {
    return (
        <Link to={pagePreview.url ?? ""} className={multipleClassName(styles.RMSPagePreviewElement, "rms-clickable rms-hover-move-up")}>
            <div className={styles.ImageContainer}>
                <img src={rmsCmsImageUrl(pagePreview.title_picture ?? "")} alt={pagePreview.title} />
            </div>
            <div className={styles.MetaContainer}>
                <h3 className={styles.Title}>{pagePreview.title}</h3>
                <div className={styles.Preview}>{pagePreview.preview}</div>
                <div><button className={"primary rms-clickable rms-hover-scale"}>Weiterlesen</button></div>
            </div>
        </Link>
    );
}