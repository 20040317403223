import styles from './RMSEmployeeSmall.module.scss';
import {motion} from "framer-motion"
import rmsCmsImageUrl from '../../../../../cms/utils/rmsCmsImageUrl';
import {configOpacityScale} from '../../../../../../main/elements/animations/framer-motion/motion-configs';
import RMSTechnologiesContainer
    from '../../../technologies/components/rms-technologies-container/RMSTechnologiesContainer';
import { Link } from 'react-router-dom';
import {RMSEmployee} from '../../models/RMSEmployee';
import RMSEmployeeProfilePicture from '../rms-employee-profile-picture/RMSEmployeeProfilePicture';

export default function RMSEmployeeSmall({employee}: {employee: RMSEmployee}) {

    return (<motion.div {...configOpacityScale} className={styles.RMSEmployeeContainer}>
        <RMSEmployeeProfilePicture employee={employee} />
        <div className={styles.Content}>
            <Link to={"/employees/"+employee.slug}>
                <div className={styles.Name}>{employee.first_name} {employee.last_name}</div>
            </Link>
            <div className={styles.ShortDescription}>{employee.short_description}</div>
            <RMSTechnologiesContainer technologies={employee.technologies?.map(tech => tech.technology)}/>
        </div>
    </motion.div>);
}