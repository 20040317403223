import styles from './RatingHorizontalContainer.module.scss';
import multipleClassName from '../../../../../../../utils/multipleClassName';

export default function RatingHorizontalContainer({rating_percentage, text, className, showPercentage}: {
    rating_percentage: number,
    text?: string,
    className?: string,
    showPercentage?: boolean
}) {
    return (
        <div className={multipleClassName(styles.RatingHorizontalContainer, className)}>
            {text && <h4>{text}</h4>}
            <div className={styles.RatingContainer}>
                <div className={styles.RatingBar} style={{width: rating_percentage + '%'}}>
                    {showPercentage && <span>{rating_percentage}%</span>}
                </div>
            </div>
        </div>
    );
}