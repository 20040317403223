import styles from './SecondaryParallaxBanner.module.scss';
import {ParallaxBanner} from 'react-scroll-parallax';
import mountainbg2 from '../../../../../../assets/images/mountainbg2.png';
import mountainbg3 from '../../../../../../assets/images/mountainbg3.png';
import mountainbg5 from '../../../../../../assets/images/mountainbg5.png';
import mountainbg6 from '../../../../../../assets/images/mountainbg6.png';
import mountainBottom from '../../../../../../assets/images/mountainbottom.png';
import { motion } from 'framer-motion';
import {configOpacity} from '../../../animations/framer-motion/motion-configs';

export default function SecondaryParallaxBanner({}: {}) {

    return (<div className={styles.SecondaryParallaxBanner}>
                <ParallaxBanner
                    className="banner"
                    layers={[
                        {
                            image: mountainbg2,
                            translateY: [0, 45],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.3s"
                            }
                        },
                        {
                            image: mountainbg3,
                            translateY: [0, 30],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.6s"
                            }
                        },
                        {
                            image: mountainbg5,
                            translateY: [0, 20],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 0.9s",
                                marginTop: -10
                            }
                        },
                        {
                            image: mountainbg6,
                            translateY: [0, 5],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                            style: {
                                animation: "fadeInUp 1.2s"
                            }
                        }
                    ]}
                />
                <motion.img {...configOpacity} src={mountainBottom} className={styles.mountainBottom}></motion.img>
            </div>);
}