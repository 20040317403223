import styles from './RMSEmployeeProfilePicture.module.scss';
import rmsCmsImageUrl from '../../../../../cms/utils/rmsCmsImageUrl';
import { Link } from 'react-router-dom';
import {RMSEmployee} from '../../models/RMSEmployee';

export default function RMSEmployeeProfilePicture({employee}: {employee: RMSEmployee}) {

    return ( <div className={styles.ImgContainer}>
            <Link to={"/employees/"+employee.slug}>
                {employee.profile_picture && <img src={rmsCmsImageUrl(employee.profile_picture)}></img>}
            </Link>
        </div>);
}