import RMSImageMultiSize from './RMSImageMultiSize';
import RMSChoosedImageElement from '../rms-choosed-image-element/RMSChoosedImageElement';
import {CSSProperties, useRef} from 'react';

export default function RMSImageMultiSizeElement({imageMultiSize, preferredWidth, preferredHeight, className, style}: {
    imageMultiSize: RMSImageMultiSize,
    preferredWidth?: number,
    preferredHeight?: number,
    className?: string,
    style?: CSSProperties
}) {

    const innerWidth = useRef(window.innerWidth);

    const correctImage = () => {
        const windowSize = innerWidth.current

        if (imageMultiSize.image_versions.length < 2) {
            return imageMultiSize.image_versions[0];
        }

        return imageMultiSize.image_versions.reduce((previousValue, currentValue) => {
            const previousDiff = previousValue.width - windowSize
            const currentDiff = currentValue.width - windowSize

            if (currentDiff < 0) { // use the biggest version, if the previous one is below the current windowSize
                return (currentDiff > previousDiff) ? currentValue : previousValue;
            } else { // otherwise use the closest version to 0 (best value)
                if (previousDiff < 0) {
                    return currentValue;
                }
                return (currentDiff < previousDiff) ? currentValue : previousValue;
            }
        })
    }


    if (imageMultiSize.image_versions.length == 0) {
        return <div>No Image Multi Size Version!!</div>
    }

    return <RMSChoosedImageElement
        choosedImage={correctImage().image}
        preferredHeight={preferredHeight}
        preferredWidth={preferredWidth}
        className={className}
        style={style}
    />
}