import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import RMSCMSMainMenuElement from '../models/RMSCMSMainMenu';
import RMSApiConfig from '../../../api/models/RMSApiConfig';
import RMSApi from '../../../api/RMSApi';
import RMSCMSFlatMenu from '../models/RMSCMSFlatMenu';

export const fetchFlatMenu = createAsyncThunk(
    'api/cms/menu/fetchFlatMenu',
    async (payload: {
                idOrHandle: string,
                apiConfig: RMSApiConfig,
           }) => {
        try {
            //process.env.REACT_APP_API_BASE_URL
            const response = await RMSApi.get(`/api/v2/flat-menus/${payload.idOrHandle}/`, {
                ...payload.apiConfig,
            })
            const data: RMSCMSFlatMenu = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);