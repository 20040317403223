import { useEffect } from 'react';

function useSetTitle(title: string) {
    useEffect(() => {
        document.title = `RM-Softwares - ${title}`;
    }, [title]);
}

export function setDHTitle(title?: string) {
    const prefix = "RM-Softwares"

    if (title) {
        document.title = `${prefix} - ${title}`;
    } else {
        document.title = prefix;
    }

}

export default useSetTitle;