import {DHContentBlockElement} from './DHContentBlockModel';
import RichTextBlock from '../richtext-block/RichTextBlock';
import {RichtTextBlockModel} from '../richtext-block/RichtTextBlockModel';
import HomeHeaderBlock from '../home-header-block/HomeHeaderBlock';
import {HomeHeaderBlockModel} from '../home-header-block/HomeHeaderBlockModel';
import FeaturePresenterBlock from '../feature-presenter-block/FeaturePresenterBlock';
import {FeaturePresenterBlockModel} from '../feature-presenter-block/FeaturePresenterBlockModel';
import DHRichTextBlock from '../dh-richtext-block/DHRichTextBlock';
import {DHRichTextBlockModel} from '../dh-richtext-block/DHRichTextBlockModel';
import DHDownloadBlock from '../dh-download-block/DHDownloadBlock';
import {DHDownloadBlockModel} from '../dh-download-block/DHDownloadBlockModel';
import RMSVerticalInfoBlock from '../rms-vertical-info-block/RMSVerticalInfoBlock';
import {RMSVerticalInfoBlockModel} from '../rms-vertical-info-block/RMSVerticalInfoBlockModel';
import RMSVerticalSpacingBlock from '../rms-vertical-spacing-block/RMSVerticalSpacingBlock';
import {RMSVerticalSpacingBlockModel} from '../rms-vertical-spacing-block/RMSVerticalSpacingBlockModel';
import RMSMultiSizeImageBlock from '../rms-multi-size-image-block/RMSMultiSizeImageBlock';
import {RMSMultiSizeImageBlockModel} from '../rms-multi-size-image-block/RMSMultiSizeImageBlockModel';
import RMSHTMLBlock from '../rms-html-block/RMSHTMLBlock';
import {RMSHTMLModel} from '../rms-html-block/RMSHTMLModel';
import {Parallax} from 'react-scroll-parallax';
import {RMSProjectsBlockModel} from '../rms-projects-block/RMSProjectsBlockModel';
import RMSProjectsBlock from '../rms-projects-block/RMSProjectsBlock';
import RMServicesBlock from '../rms-services-block/RMServicesBlock';
import {RMSServicesBlockModel} from '../rms-services-block/RMSServicesBlockModel';
import RMTechnologiesBlock from '../rms-technologies-block/RMSTechnologiesBlock';
import {RMSTechnologiesBlockModel} from '../rms-technologies-block/RMSTechnologiesBlockModel';
import RMSEmployeesBlock from '../rms-employees-block/RMSEmployeesBlock';
import {RMSEmployeesBlockModel} from '../rms-employees-block/RMSEmployeesBlockModel';
import {RMSContactBlockModel} from '../../../../features/rms-contact/blocks/rms-contact-block/RMSContactBlockModel';
import React, {ReactElement} from 'react';
import RMSContactBlock from '../../../../features/rms-contact/blocks/rms-contact-block/RMSContactBlock';
import {
    createRMSComponentTransformerObject, Tag
} from '../../../../features/rms-component-transformer/rms-component-transformer';
import {motion} from 'framer-motion';
import {configOpacitySlideRight, configOpacitySlideUp} from '../../animations/framer-motion/motion-configs';
import ContactBlock from '../contact-block/ContactBlock';
import RMSGalleryBlock from '../rms-gallery-block/RMSGalleryBlock';
import {RMSGalleryModel} from '../rms-gallery-block/RMSGalleryModel';
import RMSPagePreviewBlock from '../rms-page-preview-block/RMSPagePreviewBlock';
import {RMSPagePreviewBlockModel} from '../rms-page-preview-block/RMSPagePreviewBlockModel';
import RMSCodeBlock from '../rms-code-block/RMSCodeBlock';
import {RMSCodeBlockModel} from '../rms-code-block/RMSCodeBlockModel';

export default function DHContentBlock({blocks}: {blocks: DHContentBlockElement[]}) {

    const mapBlock = (block: DHContentBlockElement, index: number) => {
        switch (block.type) {
            case 'rms_rich_text':
                return <DHRichTextBlock key={index} blockElement={block as DHRichTextBlockModel} />;
            case 'rms_vertical_spacing':
                return <RMSVerticalSpacingBlock key={index} blockElement={block as RMSVerticalSpacingBlockModel} />;
            case 'dh_home_header':
                return <HomeHeaderBlock key={index} blockElement={block as HomeHeaderBlockModel} />;
            case 'dh_feature_presenter':
                return <FeaturePresenterBlock key={index} blockElement={block as FeaturePresenterBlockModel} />;
            case 'dh_download':
                return <DHDownloadBlock key={index} blockElement={block as DHDownloadBlockModel} />;
            case 'rms_multi_size_image':
                return <RMSMultiSizeImageBlock key={index} blockElement={block as RMSMultiSizeImageBlockModel} />;
            case 'html':
                return <RMSHTMLBlock key={index} blockElement={block as RMSHTMLModel} />;
            case 'rms_vertical_info':
                return <RMSVerticalInfoBlock key={index} blockElement={block as RMSVerticalInfoBlockModel} />;
            case 'rms_projects':
                return <RMSProjectsBlock key={index} blockElement={block as RMSProjectsBlockModel} />;
            case 'rms_services':
                return <RMServicesBlock key={index} blockElement={block as RMSServicesBlockModel} />;
            case 'rms_technologies':
                return <RMTechnologiesBlock key={index} blockElement={block as RMSTechnologiesBlockModel} />;
            case 'rms_employees':
                return <RMSEmployeesBlock key={index} blockElement={block as RMSEmployeesBlockModel} />;
            case 'rms_contact':
                return <ContactBlock key={index} blockElement={block as RMSContactBlockModel} />;
            case 'rms_gallery':
                return <RMSGalleryBlock key={index} blockElement={block as RMSGalleryModel} />;
            case 'rms_page_preview':
                return <RMSPagePreviewBlock key={index} blockElement={block as RMSPagePreviewBlockModel} />;
            case 'rms_code':
                return <RMSCodeBlock key={index} blockElement={block as RMSCodeBlockModel} />;
            default:
                return <div key={index} color="red">No Rendering for {block.type}</div>
        }
    }

    return (<>
        { blocks.map((content: any, index: number) => mapBlock(content, index))}
        </>
    );
}