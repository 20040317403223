import {createAsyncThunk} from '@reduxjs/toolkit';
import {RMSCMSPage} from '../models/RMSCMSPage';
import axios from 'axios';
import RMSApi from '../../../api/RMSApi';
import {useRMSApiConfig} from '../../../api/contexts/RMSApiContext';
import RMSApiConfig from '../../../api/models/RMSApiConfig';
import {useParams} from 'react-router-dom';

export const fetchPage = createAsyncThunk(
    'api/cms/page/fetchPage',
    async (payload: {
                pageId: string,
                endpoint?: string,
                params?: any,
                apiConfig: RMSApiConfig
           }) => {
        try {
            const response = await RMSApi.get(payload.endpoint ? payload.endpoint : `/api/v2/pages/find/`, {
                ...payload.apiConfig,
                params: {
                    html_path: payload.pageId,
                    ...(payload.params ?? {})
                }
            });
            const pageData: RMSCMSPage = response.data;
            return pageData;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);