import styles from '../main/elements/blocks/rms-vertical-info-block/RMSVerticalInfoBlock.module.scss';

export default function rmsWidthClass(width: string|undefined): string {
    switch (width) {
        case "sm": return "widthSm";
        case "md": return "widthMd";
        case "lg": return "widthLg";
        case "xl": return "widthXl";
        default: return "";
    }
}