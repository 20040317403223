import {useSelector} from 'react-redux';
import {RMSApiContainer} from '../models/RMSApiContainer';

/**
 * useApiContainer is a custom React hook that facilitates extracting data from an RMSApiContainer
 * stored in the Redux store, given a selector function that maps the current state to the relevant
 * RMSApiContainer. This hook simplifies the process of accessing data from an RMSApiContainer in a
 * Redux-managed state.
 *
 * @param {Function} apiContainer - A selector function that takes the RootState as input and returns
 * an RMSApiContainer or undefined. This function helps in selecting the appropriate RMSApiContainer
 * from the Redux store.
 *
 * @returns {RMSApiContainer<PayloadType> | undefined} - The selected RMSApiContainer or undefined if
 * it is not found in the Redux store.
 *
 * @example
 *
 * const {
 * payload: product,
 * loadingState: productLoadingState,
 * error: productLoadingError} = useApiContainer<YourRootStateInterface, Product>((state: RootState) => state.api.products.find((apiContainer) => apiContainer.payload.id === productId));}
 *
 */
export function useApiContainer<RootState, PayloadType>(apiContainer: (state: RootState) => RMSApiContainer<PayloadType> | undefined): RMSApiContainer<PayloadType> | undefined {
    return useSelector((state: RootState) => apiContainer(state));
}