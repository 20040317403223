// features/dictionary/dictionarySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import StatusDialog from '../../../features/rms-status-dialog/models/StatusDialog';
interface DialogsState {
    dialogs: StatusDialog[];
}

const initialState: DialogsState = {
    dialogs: [],
};

export const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        addDialog: (state, action: PayloadAction<StatusDialog>) => {
            state.dialogs.push(action.payload);
        },
        addDialogs: (state, action: PayloadAction<StatusDialog[]>) => {
            state.dialogs = action.payload;
        },
        setOpenStatus: (state, action: PayloadAction<{ id: number, open: boolean }>) => {
            const dialog = state.dialogs.find((dialog) => dialog.id === action.payload.id);
            if (dialog) {
                dialog.open = action.payload.open;
            }
        },
        removeDialog: (state, action: PayloadAction<number>) => {
            state.dialogs = state.dialogs.filter((dialog) => dialog.id !== action.payload);
        }
    },
});

export const {
    addDialog,
    addDialogs,
    setOpenStatus,
    removeDialog
} = dialogsSlice.actions;

export default dialogsSlice.reducer;