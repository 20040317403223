import {RMSCVSection} from '../../../models/RMSEmployee';
import styles from './CVSection.module.scss';
import multipleClassName from '../../../../../../../utils/multipleClassName';
import {getDateFromString, getMonthYearString} from '../../../../../../rms-utils/utils/date/date-utils';
import { motion } from 'framer-motion';
import {
    configOpacity,
    configOpacityScale, configOpacitySlideUp
} from '../../../../../../../main/elements/animations/framer-motion/motion-configs';

export default function CVSection({cvSection, showLine = true, className}: {
    cvSection: RMSCVSection,
    showLine?: boolean,
    className?: string
}) {


    const from_date = getDateFromString(cvSection.from_date);
    const to_date = getDateFromString(cvSection.to_date);
    let from_to_string = null
    if (from_date) {
        from_to_string = getMonthYearString(from_date) + " - " + getMonthYearString(to_date, "Jetzt");
    }

    return <motion.div {...configOpacityScale} className={multipleClassName(styles.CVSection, className)}>
        <div
            className={multipleClassName(styles.Header)}>
            <div className={multipleClassName(styles.YearContainer, styles.Header)}>
                {
                    from_date?.getFullYear() ?? "unknown"
                }
            </div>
            <div className={styles.TimeLineContainer}>
                <div className={styles.StartingPoint}>

                </div>
            </div>
            <div className={styles.InformationContainer}>
                {cvSection.position}
            </div>
        </div>
        <div
            className={styles.Content}>
            <div className={styles.YearContainer}>
                {cvSection.location}
            </div>
            <div
                className={styles.TimeLineContainer}>
                {showLine && <div className={styles.Line}></div>}
            </div>
            <div className={styles.InformationContainer}>
                <div className={styles.Company}>{cvSection.name} - {from_to_string && <span className={styles.Time}>{from_to_string}</span>}</div>
                <div className={styles.Description}>{cvSection.short_description}</div>
            </div>
        </div>
    </motion.div>
}