import {useEffect} from 'react';

interface MatomoConfig {
    siteId: string;
    trackerUrl: string;
    disableCookies?: boolean;
    requireCookieConsent?: boolean;
    disableTrackPageView?: boolean; // if you want a custom trackPageView
    customDimensions?: Array<{id: number; value: string}>;
}

const useMatomoTracking = ({
                               siteId,
                               trackerUrl,
                               disableCookies = false,
                               customDimensions = [],
                                disableTrackPageView = false,
                               requireCookieConsent = true
                           }: MatomoConfig) => {
    useEffect(() => {
        window._paq = window._paq || [];
        if (disableCookies) window._paq.push(['disableCookies']);
        if (requireCookieConsent) window._paq.push(['requireCookieConsent']);
        // add later general consent
        // _paq.push(['requireConsent']);

        customDimensions.forEach(dimension => {
            window._paq.push(['setCustomDimension', dimension.id, dimension.value]);
        });

        if (!disableTrackPageView) {
            window._paq.push(['trackPageView']);
        }
        window._paq.push(['setTrackerUrl', trackerUrl + 'matomo.php']);
        window._paq.push(['setSiteId', siteId]);

        const g = document.createElement('script');
        g.async = true; g.src = trackerUrl + 'matomo.js';
        const s = document.getElementsByTagName('script')[0];
        if (s && s.parentNode) {
            s.parentNode.insertBefore(g, s);
        } else {
            document.head.appendChild(g);
        }
    }, [siteId, trackerUrl, disableCookies, customDimensions]);
};

export default useMatomoTracking;