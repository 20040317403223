import styles from './WaveContainer.module.scss';
import { ReactComponent as WhiteCurve } from '../../../../../../assets/images/white_curve.svg';
import multipleClassName from '../../../../../utils/multipleClassName';

export default function WaveContainer({children, className, topCurveClassName}: {
    children: React.ReactNode,
    className?: string,
    topCurveClassName?: string
}) {

    return (
        <div className={multipleClassName(styles.WaveContainer, className ?? '')}>
            <div className={multipleClassName(styles.TopCurve, topCurveClassName ?? '')}>
                <WhiteCurve/>
            </div>
            {children}
        </div>);
}