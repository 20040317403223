// features/dictionary/dictionarySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SlideImage} from 'yet-another-react-lightbox';

interface LightboxSlidesGroup {
    slides: SlideImage[];
}

interface SelectedLightboxGroup {
    key: string;
    index: number;
}

interface LightboxState {
    slideGroups: { [key: string]: LightboxSlidesGroup };
    selectedGroup?: SelectedLightboxGroup;
}

const initialState: LightboxState = {
    slideGroups: {},
};

export const lightboxSlice = createSlice({
    name: 'lightbox',
    initialState,
    reducers: {
        addOrReplaceSlideGroup: (state, action: PayloadAction<{ key: string; slideGroup: LightboxSlidesGroup }>) => {
            state.slideGroups[action.payload.key] = action.payload.slideGroup;
        },
        addToSlideGroup: (state, action: PayloadAction<{ key: string; slideImage: SlideImage }>) => {
            const key = action.payload.key;
            // create a new group, if it doesn't exist
            if (!state.slideGroups[key]) {
                state.slideGroups[key] = { slides: [] };
            }
            state.slideGroups[key].slides = state.slideGroups[key].slides.filter((slide) => slide.src !== action.payload.slideImage.src);
            state.slideGroups[key].slides.push(action.payload.slideImage);
        },
        removeFromSlideGroup: (state, action: PayloadAction<{ key: string; slideImage: SlideImage }>) => {
            const key = action.payload.key;
            if (state.slideGroups[key]) {
                state.slideGroups[key].slides = state.slideGroups[key].slides.filter((slide) => slide.src !== action.payload.slideImage.src);
            }
        },
        removeSlidesInGroup: (state, action: PayloadAction<{ key: string }>) => {
            if (state.slideGroups[action.payload.key]) {
                state.slideGroups[action.payload.key].slides = [];
            }
        },
        setSelectedGroup: (state, action: PayloadAction<{ key: string; img: string } | undefined>) => {
            if (action.payload === undefined) {
                state.selectedGroup = undefined;
                return;
            }
            state.selectedGroup = {
                key: action.payload.key,
                index: state.slideGroups[action.payload.key].slides.findIndex((slide) => slide.src === action.payload?.img),
            }
        }
    },
});

export const {
    addOrReplaceSlideGroup,
    addToSlideGroup,
    setSelectedGroup,
    removeSlidesInGroup,
    removeFromSlideGroup
} = lightboxSlice.actions;

export default lightboxSlice.reducer;