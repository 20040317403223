import multipleClassName from '../../../../utils/multipleClassName';
import styles from './RMSContactBlock.module.scss';
import transformToReactStyle, {
    transformToReactMaxWidthContainerStyle
} from '../../../../main/elements/components/elements/rms-css-style-block/transformToReactStyle';
import transformToReactContainerClasses
    from '../../../../main/elements/components/elements/rms-css-style-block/transformToReactClasses';
import {RMSContactBlockModel} from './RMSContactBlockModel';
import {defaultTransformerObject} from '../../../rms-component-transformer/rms-component-transformer';
import {useForm} from 'react-hook-form';
import React, {useEffect} from 'react';
import {CircularProgress, TextField} from '@mui/material';
import {useApiContainer} from '../../../api/hooks/useApiContainer';
import {RootState} from '../../../../main/store';
import ContactRequest from '../../models/ContactRequest';
import {useDispatch} from 'react-redux';
import {useRMSApiConfig} from '../../../api/contexts/RMSApiContext';
import {createContactRequest} from '../../actions/createContactRequest';
import {LoadingState} from '../../../api/models/LoadingState';
import RMSEmployeeProfilePicture
    from '../../../rms-projects/features/employee/components/rms-employee-profile-picture/RMSEmployeeProfilePicture';
import ContactElement from '../../../rms-projects/features/general/components/elements/contact-element/ContactElement';

export default function RMSContactBlock({blockElement, htmlComponentTransformers
}: {
    blockElement: RMSContactBlockModel
    htmlComponentTransformers?: {
        headerElement?: typeof defaultTransformerObject;
        contentElement?: typeof defaultTransformerObject;
    }
}) {
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const dispatch = useDispatch();
    const apiConfig = useRMSApiConfig();
    const contactRequestAPI = useApiContainer<RootState, ContactRequest>(
        (state: RootState) => state.api.contact.contactRequest);

    const data = blockElement.value
    const headerTransformer = htmlComponentTransformers?.headerElement ?? defaultTransformerObject;
    const contentTransformer = htmlComponentTransformers?.contentElement ?? defaultTransformerObject;

    console.log("contctRequest", contactRequestAPI);

    useEffect(() => {
        if (contactRequestAPI?.loadingState === LoadingState.Success) {
            reset();
        }
    }, [contactRequestAPI?.loadingState]);

    const onSubmit = (data: ContactRequest) => {
        dispatch(createContactRequest({contactRequest: data, apiConfig: apiConfig}) as any);
    }

    const employee = blockElement.value.employee;

    return (
        <div
            className={multipleClassName(styles.RMSContactBlock, "IgnoreMaxWidth", "MaxWidthOuterContainer")}
            style={transformToReactStyle(blockElement.value.css_style)}
        >
            <div className={multipleClassName(
                styles.inner,
                "MaxWidthContainer",
                transformToReactContainerClasses(blockElement.value.css_style))}
                 style={transformToReactMaxWidthContainerStyle(blockElement.value.css_style)}>
                <div className={styles.content}>
                    {
                        employee && <div className={styles.Employee}>
                            <RMSEmployeeProfilePicture employee={employee}/>
                            <div className={styles.ContactElements}>
                                <div className={styles.Social}>
                                    <ContactElement iconClassName={"bi bi-linkedin"}
                                                    text="LinkedIn"
                                                    link={employee.linkedin}
                                                    className={styles.ContactElement}/>
                                    <ContactElement iconClassName={"bi bi-github"}
                                                    text="Github"
                                                    link={employee.github}
                                                    className={styles.ContactElement}/>
                                </div>
                                <ContactElement iconClassName={"bi bi-telephone-fill"}
                                                text={employee.phone_number}
                                                link={"tel:"+employee.phone_number}
                                                className={styles.ContactElement}/>
                                <ContactElement iconClassName={"bi bi-envelope-at-fill"}
                                                text={employee.email}
                                                link={"mailto:"+employee.email}
                                                className={styles.ContactElement}/>
                            </div>
                        </div>
                    }
                    <form className={styles.Form} onSubmit={handleSubmit((data) => onSubmit(data as any))}>
                        <headerTransformer.div className={multipleClassName(styles.Inner, styles.Left)}>
                            <TextField
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: "Bitte geben Sie eine Email-Adresse ein."
                                    },
                                    pattern: {
                                        value: /\S+@\S+\.\S+/,
                                        message: "Falsches Format. Bitte geben Sie eine gültige Email-Adresse ein.",
                                    }
                                })}
                                className={styles.EmailField}
                                error={!!errors.email} helperText={errors.email && errors.email.message as string}
                                size="small" required={true} id="outlined-basic" label="Email" variant="outlined"
                            />
                            <TextField
                                {...register('first_name', { required: false })}
                                size="small" id="outlined-basic" label="Vorname" variant="outlined" />
                            <TextField
                                {...register('last_name', { required: false })}
                                size="small" id="outlined-basic" label="Nachname" variant="outlined" />
                        </headerTransformer.div>
                        <contentTransformer.div className={multipleClassName(styles.Inner, styles.Right)}>
                            <TextField
                                {...register('message', { required: true })}
                                error={!!errors.message}
                                helperText={errors.message && "Bitte geben Sie eine Nachricht ein."}
                                size="small" required={true}
                                multiline
                                minRows={5}
                                id="outlined-basic" label="Nachricht" variant="outlined" />
                            {contactRequestAPI?.loadingState == LoadingState.Error && <div style={{color: 'red'}}>
                                Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.
                            </div>}
                            {contactRequestAPI?.loadingState == LoadingState.Success && <div style={{color: 'green'}}>
                                Vielen Dank für Ihre Kontaktanfrage. Wir werden uns in Kürze bei Ihnen melden.
                            </div>}
                            {contactRequestAPI?.loadingState === LoadingState.Loading ?
                                <CircularProgress /> :
                                <button type="submit" className={multipleClassName( "primary", "rms-hover-scale")}>
                                    Senden
                                </button>
                            }
                        </contentTransformer.div>
                    </form>
                </div>
            </div>
        </div>
    );
}