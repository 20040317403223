export function getGermanMonthName(date?: Date): string | null {
    if (!date) {
        return null
    }
    const germanMonthNames = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun",
        "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];

    return germanMonthNames[date.getMonth()];
}

export function getDateFromString(dateString?: string | Date): Date | undefined {
    if (!dateString) {
        return undefined;
    }
    return new Date(dateString);
}

export function getMonthYearString(date?: Date, defaultValue?: string): string | null {
    if (!date) {
        return defaultValue || null;
    }
    return `${getGermanMonthName(date)} ${date.getFullYear()}`;
}