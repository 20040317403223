import {createAsyncThunk} from '@reduxjs/toolkit';
import RMSApiConfig from '../../api/models/RMSApiConfig';
import RMSApi from '../../api/RMSApi';

export const getStatusDialogs = createAsyncThunk(
    'api/v2/status-dialogs',
    async (payload: {
                apiConfig: RMSApiConfig,
           }) => {
        try {
            const response = await RMSApi.get(`/api/v2/status-dialogs/`,{
                ...payload.apiConfig,
            })
            const data: any = response.data;
            return data;
        } catch (error) {
            throw error;
        }
    }
);