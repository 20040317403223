import styles from './RMSPageMetadata.module.scss';
import {RMSPageModel} from '../../../pages/RMSPage/RMSPageModel';
import RMSBreadcrumb from '../rms-breadcrumb/RMSBreadcrumb';
import React from 'react';

export default function RMSPageMetadata({page}: {page: RMSPageModel}) {
    return (
        <div className={styles.RMSPageMetadata}>
            {page.show_breadcrumbs && page.breadcrumb_parts && page.breadcrumb_parts.length > 0 &&
                <RMSBreadcrumb breadcrumb_parts={page.breadcrumb_parts.filter(value => value.title !== "Root")}/>
            }
            {page.show_date && <div className={styles.Date}>{page.date}</div>}
        </div>
    );
}