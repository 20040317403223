import { useSelector } from 'react-redux';
import {RootState} from '../../../main/store';
import {useApiContainer} from '../../api/hooks/useApiContainer';
import {RMSCMSPage} from '../pages/models/RMSCMSPage';
import RMSCMSMainMenuElement from '../menus/models/RMSCMSMainMenu';
import RMSCMSFlatMenu from '../menus/models/RMSCMSFlatMenu';

export function useFlatMenuWithState() {
    const pageData = useApiContainer<RootState, RMSCMSFlatMenu>(
        (state: RootState) => state.api.cms.footerMenu);
    return {
        footerMenu: pageData?.payload,
        loadingState: pageData?.loadingState,
        error: pageData?.error
    };
}
