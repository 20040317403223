import {RMSCodeBlockModel} from './RMSCodeBlockModel';
import styles from './RMSCodeBlock.module.scss';
import multipleClassName from '../../../../utils/multipleClassName';
import transformToReactStyle, {
    transformToReactMaxWidthContainerStyle
} from '../../components/elements/rms-css-style-block/transformToReactStyle';
import transformToReactContainerClasses from '../../components/elements/rms-css-style-block/transformToReactClasses';
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark, dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

export default function RMSCodeBlock({blockElement}: {blockElement: RMSCodeBlockModel}) {
    const data = blockElement.value

    return (
        <div
            className={multipleClassName(styles.RMSCodeBlock, "IgnoreMaxWidth", "MaxWidthOuterContainer")}
            style={transformToReactStyle(blockElement.value.css_style)}
        >
            <div className={multipleClassName(
                styles.inner,
                "MaxWidthContainer",
                transformToReactContainerClasses(blockElement.value.css_style))}
                 style={transformToReactMaxWidthContainerStyle(blockElement.value.css_style)}>
                <SyntaxHighlighter language={data.language ?? "python"} style={dracula} showLineNumbers={true}>
                    {data.code ?? ""}
                </SyntaxHighlighter>
            </div>
        </div>
    );
}