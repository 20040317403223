import {ActionReducerMapBuilder, AsyncThunk} from '@reduxjs/toolkit';
import createAPIActionReducerLogic from '../../api/utils/createAPIActionReducerLogic';
import {rmsStatusDialogInitialState} from './rmsStatusDialogReducer';
import {getStatusDialogs} from '../actions/getStatusDialogs';

export const statusDialogReducerLogic = (builder: ActionReducerMapBuilder<typeof rmsStatusDialogInitialState>) => {
    createAPIActionReducerLogic<typeof getStatusDialogs, typeof rmsStatusDialogInitialState>(
        builder,
        getStatusDialogs,
        (state) => state.statusDialogs,
        (state, newValue) => state.statusDialogs = newValue);
};