import styles from './RMSTechnologiesContainer.module.scss';
import {RMSTechnology} from '../../models/RMSTechnology';
import RMSTechnologyShort from '../rms-technology-short/RMSTechnologyShort';
import multipleClassName from '../../../../../../utils/multipleClassName';

export default function RMSTechnologiesContainer({technologies, className}: {
    technologies: RMSTechnology[] | undefined,
    className?: string
}) {

    if (!technologies) {
        return null;
    }

    return (<div className={multipleClassName(styles.TechnologiesContainer, className)}>
        {technologies.map((technology, index) => {
            return <RMSTechnologyShort key={index} technology={technology} />
        })}
    </div>);
}