import styles from './RMSServiceSmall.module.scss';
import {motion} from "framer-motion"
import rmsCmsImageUrl from '../../../../../cms/utils/rmsCmsImageUrl';
import {configOpacityScale} from '../../../../../../main/elements/animations/framer-motion/motion-configs';
import RMSTechnologiesContainer
    from '../../../technologies/components/rms-technologies-container/RMSTechnologiesContainer';
import { Link } from 'react-router-dom';
import {RMSService} from '../../models/RMSService';

export default function RMSServiceSmall({service}: {service: RMSService}) {

    return (<motion.div {...configOpacityScale} className={styles.RMSServiceContainer}>
        <div className={styles.ImgContainer}>
            <Link to={"/services/"+service.slug}>
                {service.title_picture && <img src={rmsCmsImageUrl(service.title_picture)}></img>}
            </Link>
        </div>
        <div className={styles.Content}>
            <Link to={"/services/"+service.slug}>
                <div className={styles.Name}>{service.name}</div>
            </Link>
            <div className={styles.ShortDescription}>{service.name}</div>
            <RMSTechnologiesContainer technologies={service.related_technologies?.map(tech => tech.technology)}/>
        </div>
    </motion.div>);
}