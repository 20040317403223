import styles from './DHRichTextElement.module.scss';
import multipleClassName from '../../../../../utils/multipleClassName';
import rmsWidthClass from '../../../../../utils/rmsWidthClass';
import DHRichText from './DHRichTextModel';
import rmsAlignmentClass from '../../../../../utils/rmsAlignmentClass';
import RMSImageMultiSizeElement from '../rms-image-multi-size-element/RMSImageMultiSizeElement';
import rmsMultiSizeImgValid from '../rms-image-multi-size-element/utils/rmsMultiSizeImgValid';
import transformToReactStyle, {
    transformToReactMaxWidthContainerStyle
} from '../rms-css-style-block/transformToReactStyle';
import rmsCmsImageUrl from '../../../../../features/cms/utils/rmsCmsImageUrl';
import parse from 'html-react-parser';
import {useDispatch} from 'react-redux';
import {addToSlideGroup, setSelectedGroup} from '../../../../redux/slices/lightboxSlice';
import {useEffect, useState} from 'react';

export default function DHRichTextElement({dhRichText}: {dhRichText: DHRichText}) {
    const dispatch = useDispatch();
    const [richText, setRichText] = useState(<div>No Content</div>);

    useEffect(() => {
        let rich_text = dhRichText.rich_text;

        rich_text = rich_text.replace(/src=["'](\/[^"^']+)["']/g, `src="${rmsCmsImageUrl("")}$1"`);
        rich_text = rich_text.replace(/href=["'](\/[^"^']+)["']/g, `href="${rmsCmsImageUrl("")}$1"`);

        setRichText(parse(rich_text, {
            replace(domNode) {
                if ((domNode as any).name === 'img') {
                    dispatch(addToSlideGroup({ key: 'page', slideImage: {
                            src: (domNode as any).attribs.src,
                            alt: (domNode as any).attribs.alt,
                            description: (domNode as any).attribs.alt
                        }}));

                    return <img
                        className={multipleClassName("rms-clickable rms-hover-move-up")}
                        {...(domNode as any).attribs
                        }
                        onClick={() => {dispatch(setSelectedGroup({
                            key: 'page',
                            img: (domNode as any).attribs.src
                        }))}}
                    />;
                }
            }
        }) as any)
    }, [dhRichText]);

    return (
        <div className={multipleClassName(styles.RichTextElementContainer, "IgnoreMaxWidth")}
        style={transformToReactMaxWidthContainerStyle(dhRichText.css_style)}>
            <div className={styles.backgroundImage}>
                { rmsMultiSizeImgValid(dhRichText.background_image) && <RMSImageMultiSizeElement imageMultiSize={dhRichText.background_image!} /> }
            </div>
            <div className={
                multipleClassName(styles.RichTextElement, rmsWidthClass(dhRichText.width), rmsAlignmentClass(dhRichText.alignment))
            }
                 style={transformToReactStyle(dhRichText.css_style)}>
                {richText}
            </div>
        </div>
    );
}