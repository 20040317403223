import {ActionReducerMapBuilder, AsyncThunk} from '@reduxjs/toolkit';
import createAPIActionReducerLogic from '../../api/utils/createAPIActionReducerLogic';
import {rmsContactInitialState} from './rmsContactReducer';
import {createContactRequest} from '../actions/createContactRequest';

export const contactRequestReducerLogic = (builder: ActionReducerMapBuilder<typeof rmsContactInitialState>) => {
    createAPIActionReducerLogic<typeof createContactRequest, typeof rmsContactInitialState>(
        builder,
        createContactRequest,
        (state) => state.contactRequest,
        (state, newValue) => state.contactRequest = newValue);
};