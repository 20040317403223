import { useSelector } from 'react-redux';
import {RootState} from '../../../main/store';
import {useApiContainer} from '../../api/hooks/useApiContainer';
import {RMSCMSPage} from '../pages/models/RMSCMSPage';
import RMSCMSMainMenuElement from '../menus/models/RMSCMSMainMenu';

export function useMainMenuWithPage() {
    const pageData = useApiContainer<RootState, RMSCMSMainMenuElement[]>(
        (state: RootState) => state.api.cms.mainMenu);
    return {
        mainMenuItems: pageData?.payload,
        loadingState: pageData?.loadingState,
        error: pageData?.error
    };
}
